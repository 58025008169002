import React from "react"

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <defs>
        <path
          id="path-1"
          d="M0.00002734375 0.00002734375L35 0.00002734375 35 35 0.00002734375 35z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-163 -696)">
          <g transform="translate(79 696)">
            <g transform="translate(84)">
              <g>
                <mask id="mask-2" fill="#fff">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <path
                  className="backcolor"
                  d="M27.488 21.643c-.049 1.07-.219 1.8-.467 2.44a5.138 5.138 0 01-2.939 2.938c-.639.248-1.37.418-2.439.467-1.072.05-1.413.06-4.143.06-2.729 0-3.071-.01-4.143-.06-1.07-.049-1.8-.219-2.44-.467a4.921 4.921 0 01-1.779-1.16 4.921 4.921 0 01-1.16-1.779c-.247-.639-.417-1.37-.466-2.439-.05-1.072-.06-1.414-.06-4.143s.01-3.071.06-4.143c.049-1.07.218-1.8.466-2.44a4.93 4.93 0 011.16-1.779 4.92 4.92 0 011.78-1.16c.639-.247 1.37-.417 2.439-.466 1.072-.05 1.414-.06 4.143-.06s3.071.01 4.143.06c1.07.049 1.8.219 2.44.466a4.94 4.94 0 011.78 1.16c.51.502.906 1.11 1.158 1.78.249.639.418 1.37.468 2.439.048 1.072.06 1.414.06 4.143s-.012 3.071-.061 4.143M17.5 0C7.837 0 0 7.837 0 17.5S7.837 35 17.5 35 35 27.163 35 17.5 27.163 0 17.5 0"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                className="backcolor"
                d="M22.864 13.342a1.206 1.206 0 110-2.413 1.206 1.206 0 010 2.413M17.5 22.66a5.16 5.16 0 110-10.32 5.16 5.16 0 010 10.32m8.18-9.22c-.045-.98-.209-1.513-.347-1.867a3.108 3.108 0 00-.751-1.155 3.11 3.11 0 00-1.155-.751c-.354-.138-.887-.302-1.866-.346-1.06-.049-1.378-.059-4.061-.059s-3.001.01-4.06.058c-.98.045-1.513.209-1.867.347a3.11 3.11 0 00-1.155.751 3.12 3.12 0 00-.752 1.155c-.137.354-.3.887-.346 1.866-.048 1.06-.059 1.378-.059 4.061s.011 3.001.06 4.061c.044.98.208 1.512.345 1.866.161.437.418.831.752 1.155.324.334.718.59 1.155.752.354.137.887.301 1.866.346 1.06.048 1.377.059 4.061.059 2.683 0 3.001-.01 4.06-.06.98-.044 1.513-.208 1.867-.345a3.327 3.327 0 001.906-1.907c.138-.354.302-.886.347-1.866.048-1.06.058-1.378.058-4.061s-.01-3.001-.058-4.06"
              ></path>
              <path
                className="backcolor"
                d="M20.85 17.5a3.35 3.35 0 11-6.7 0 3.35 3.35 0 016.7 0"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Instagram
